/* eslint-disable eqeqeq */
import { createActions, Store } from 'reflux'
import Proxies from 'Components/proxies'
const { GET, POST } = Proxies
import moment from 'moment'
import 'moment/locale/da'

export const InvoiceActions = createActions([
  'getBrands',
  'getInvoices',
  'getInvoice',
  'updateInvoiceField',
  'createEmptyInvoice',
  'copyInvoice',
  'saveInvoice',
  'getAdminInvoices',
  'processInvoices',
  'clearToast',
  'getWeightPool',
  'getAdminWeightPool',
  'getInvoiceElements',
  'getAdminInvoiceElements',
  'getUnique',
  'getParcelBrands'
])

export class InvoiceStore extends Store {
  constructor(props) {
    super(props)
    this.listenables = InvoiceActions
    this.state = {
      invoiceBrands: [],
      invoiceBrandsForFreightOverview: [],
      fetchingBrands: false,
      invoices: [],
      fetchingInvoices: false,
      invoiceData: [],
      fetchingInvoiceData: false,
      invoiceLine: {},
      fetchingInvoiceLine: false,
      isCopyingInvoice: false,
      isSaving: false,
      currBrandID: null,
      totalOpenInvoiceAmount: 0,
      totalClosedInvoiceThisMonth: 0,
      totalClosedInvoiceLastMonth: 0,
      totalClosedInvoiceTwoMonthsBack: 0,
      totalOpenOrderAmount: 0,
      processingInvoice: false,
      invoiceProcessSuccess: false,
      invoiceProcessError: false,
      weights: [],
      fetchingWeightPool: false,
      fetchingWeightPoolError: false,
      freightElements: [],
      fetchingFreightElements: false,
      fetchingFreightElementsError: false,
      invoiceBrandsForParcelOverview: []
    }
  }
  createEmptyInvoice(BrandID) {
    if (this.state.isCopyingInvoice) {
      this.setState({ isCopyingInvoice: false })
    } else {
      let invoiceLine = {
        invoiceTitle: '',
        invoiceStatus: 'OPEN',
        timeEnd: moment()
          .endOf('day')
          .toString(),
        timeStart: moment()
          .startOf('day')
          .toString(),
        originBrandID: parseInt(BrandID)
      }
      this.setState({ invoiceLine: invoiceLine })
    }
  }
  getBrands(type) {
    this.setState({ fetchingBrands: true })
    GET('orders/brands', type).then(responseJSON => {
      let nextState = {
        fetchingBrands: false,
        invoiceBrands: [],
        invoiceBrandsForFreightOverview: []
      }
      if (responseJSON && responseJSON.length) {
        nextState.invoiceBrands = type && type.isFreightOverview ? [] : responseJSON
        nextState.invoiceBrandsForFreightOverview = type && type.isFreightOverview ? responseJSON : []
        nextState.totalOpenInvoiceAmount = responseJSON.reduce((acc, cur) => acc + (cur.openInvoiceAmount || 0), 0)
        nextState.totalClosedInvoiceThisMonth = responseJSON.reduce((acc, cur) => acc + (cur.closedInvoiceThisMonth || 0), 0)
        nextState.totalClosedInvoiceLastMonth = responseJSON.reduce((acc, cur) => acc + (cur.closedInvoiceLastMonth || 0), 0)
        nextState.totalClosedInvoiceTwoMonthsBack = responseJSON.reduce((acc, cur) => acc + (cur.closedInvoiceTwoMonthsBack || 0), 0)
        nextState.totalOpenOrderAmount = responseJSON.reduce((acc, cur) => acc + (cur.openOrdersAmount || 0), 0)
        nextState.totalTodayShippedAmount = responseJSON.reduce((acc, cur) => acc + (cur.todayShippedAmount || 0), 0)
      }
      this.setState(nextState)
    })
  }
  getInvoices(BrandID = null) {
    if (BrandID) {
      this.setState({ fetchingInvoices: true })
      GET('invoices/pool', { originBrandID: BrandID }).then(responseJSON => {
        let nextState = {
          fetchingInvoices: false,
          invoices: []
        }
        if (responseJSON) {
          nextState.invoices = responseJSON
        }
        this.setState(nextState)
      })
    }
  }
  getAdminInvoices(BrandID = null) {
    if (BrandID) {
      this.setState({ fetchingInvoices: true, currBrandID: BrandID })
      GET('invoices/adminPool', { originBrandID: BrandID }).then(responseJSON => {
        let nextState = {
          fetchingInvoices: false,
          invoices: []
        }
        if (responseJSON) {
          nextState.invoices = responseJSON
        }
        this.setState(nextState)
      })
    }
  }
  getInvoice(InvoiceID, isAdmin) {
    this.setState({ fetchingInvoiceData: true, fetchingInvoiceLine: true })
    const apiEndpoint = isAdmin ? 'invoices/adminFetch' : 'invoices/fetch'
    GET(apiEndpoint, { InvoiceID: InvoiceID }).then(responseJSON => {
      let nextState = {
        fetchingInvoiceLine: false,
        invoiceLine: {}
      }
      if (responseJSON) {
        nextState.invoiceLine = responseJSON
      }
      this.setState(nextState)
    })
    const elementsApiEndpoint = isAdmin ? 'invoices/adminElements' : 'invoices/elements'
    GET(elementsApiEndpoint, { InvoiceID: InvoiceID }).then(responseJSON => {
      let nextState = {
        fetchingInvoiceData: false,
        invoiceData: []
      }
      if (responseJSON) {
        nextState.invoiceData = responseJSON
      }
      this.setState(nextState)
    })
  }
  updateInvoiceField(field) {
    let name = field.target.name
    let value = field.target.value
    let invoiceLine = this.state.invoiceLine
    invoiceLine[name] = value
    this.setState({ invoiceLine: invoiceLine })
  }
  copyInvoice(invoice, props) {
    let invoiceLine = {}
    Object.assign(invoiceLine, invoice)
    delete invoiceLine.InvoiceID
    invoiceLine.invoiceStatus = 'OPEN'
    this.setState({ invoiceLine: invoiceLine, isCopyingInvoice: true })
    props.history.push(props.location.pathname + '/new')
  }
  saveInvoice() {
    this.setState({ isSaving: true })
    if (this.state.invoiceLine.invoiceTitle && this.state.invoiceLine.invoiceTitle != '') {
      POST('invoices/replace', this.state.invoiceLine).then(responseJSON => {
        let nextState = {
          isSaving: false
        }
        if (responseJSON) {
          nextState.invoiceLine = responseJSON
          if (this.state.invoices.length > 0) {
            let invoices = this.state.invoices
            let found = false
            for (let i in invoices) {
              if (invoices[i].InvoiceID == responseJSON.InvoiceID) {
                invoices[i] = responseJSON
                found = true
              }
            }
            if (!found) {
              invoices.push(responseJSON)
            }
            nextState.invoices = invoices
          }
        }
        this.setState(nextState)
      })
    }
  }

  processInvoices() {
    this.setState({ processingInvoice: true })
    POST('invoices/cron-process').then(responseJSON => {
      let nextState = { processingInvoice: false, invoiceProcessSuccess: false, invoiceProcessError: false }
      if (responseJSON) {
        nextState.invoiceProcessSuccess = true
      } else {
        nextState.invoiceProcessError = true
      }
      this.setState(nextState)
    })
  }

  clearToast() {
    this.setState({ invoiceProcessSuccess: false, invoiceProcessError: false, fetchingWeightPoolError: false, fetchingFreightElementsError: false })
  }

  getWeightPool(originBrandID = null) {
    this.setState({ fetchingWeightPool: true })
    GET('invoices/weight-pool', { originBrandID }).then(responseJSON => {
      let nextState = {
        weights: [],
        fetchingWeightPool: false
      }
      if (responseJSON.error) {
        nextState.fetchingWeightPoolError = true
      } else {
        responseJSON.forEach(item => {
          if (item.weightOverview && item.weightOverview.length) {
            item.allWeights =
              item.weightOverview[0].orderWeightLessThan5 +
              item.weightOverview[0].orderWeight5And10 +
              item.weightOverview[0].orderWeight10And15 +
              item.weightOverview[0].orderWeight15And20 +
              item.weightOverview[0].orderWeightMoreThan20
          } else {
            item.allWeights = 0
          }
        })
        nextState.weights = responseJSON
      }
      this.setState(nextState)
    })
  }

  getAdminWeightPool(originBrandID = null) {
    this.setState({ fetchingWeightPool: true })
    GET('invoices/admin-weight-pool', { originBrandID }).then(responseJSON => {
      let nextState = {
        weights: [],
        fetchingWeightPool: false
      }
      if (responseJSON.error) {
        nextState.fetchingWeightPoolError = true
      } else {
        responseJSON.forEach(item => {
          if (item.weightOverview && item.weightOverview.length) {
            item.allWeights =
              item.weightOverview[0].orderWeightLessThan5 +
              item.weightOverview[0].orderWeight5And10 +
              item.weightOverview[0].orderWeight10And15 +
              item.weightOverview[0].orderWeight15And20 +
              item.weightOverview[0].orderWeightMoreThan20
          } else {
            item.allWeights = 0
          }
        })
        nextState.weights = responseJSON
      }
      this.setState(nextState)
    })
  }

  getAdminInvoiceElements(InvoiceID) {
    this.setState({ fetchingInvoiceLine: true, fetchingFreightElements: true })
    GET('invoices/adminFetch', { InvoiceID: InvoiceID }).then(responseJSON => {
      let nextState = {
        fetchingInvoiceLine: false,
        invoiceLine: {}
      }
      if (responseJSON) {
        nextState.invoiceLine = responseJSON
      }
      this.setState(nextState)
    })
    GET('invoices/adminElements', { InvoiceID }).then(responseJSON => {
      let nextState = {
        freightElements: [],
        fetchingFreightElements: false
      }
      if (responseJSON && responseJSON.error) {
        nextState.fetchingFreightElementsError = true
      } else {
        nextState.freightElements = responseJSON
      }
      this.setState(nextState)
    })
  }

  getUnique(arr, comp) {
    const unique = arr
      .map(e => e[comp])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter(e => arr[e])
      .map(e => arr[e])

    return unique
  }

  getWeightSpan(weight) {
    let weightSpan = '0-5kg'
    if (weight <= 5000) {
      weightSpan = '0-5kg'
    } else if (weight > 5000 && weight <= 10000) {
      weightSpan = '5-10kg'
    } else if (weight > 10000 && weight <= 15000) {
      weightSpan = '10-15kg'
    } else if (weight > 15000 && weight <= 20000) {
      weightSpan = '15-20kg'
    } else if (weight > 20000) {
      weightSpan = 'More than 20kg'
    }
    return weightSpan
  }

  getInvoiceElements(InvoiceID) {
    this.setState({ fetchingInvoiceLine: true, fetchingFreightElements: true })
    GET('invoices/fetch', { InvoiceID: InvoiceID }).then(responseJSON => {
      let nextState = {
        fetchingInvoiceLine: false,
        invoiceLine: {}
      }
      if (responseJSON) {
        nextState.invoiceLine = responseJSON
      }
      this.setState(nextState)
    })
    GET('invoices/elements', { InvoiceID }).then(responseJSON => {
      let nextState = {
        freightElements: [],
        fetchingFreightElements: false
      }
      if (responseJSON && responseJSON.error) {
        nextState.fetchingFreightElementsError = true
      } else {
        // responseJSON = this.getUnique(responseJSON, 'OrderID')
        let orderMap = {}
        for (let ol of responseJSON) {
          if (orderMap[ol.OrderID]) {
            orderMap[ol.OrderID].orderlineWeight += ol.orderlineWeight
          } else {
            orderMap[ol.OrderID] = ol
          }
        }
        for (let OrderID in orderMap) {
          let ol = orderMap[OrderID]
          let numParcels = ol.orderParcels ? ol.orderParcels : 1
          ol.orderlineWeight = this.getWeightSpan(ol.orderlineWeight / numParcels)
        }
        nextState.freightElements = Object.values(orderMap)
      }
      this.setState(nextState)
    })
  }

  getParcelBrands() {
    this.setState({ fetchingBrands: true })
    GET('invoices/brandsWithUserFreight').then(responseJSON => {
      let nextState = {
        fetchingBrands: false,
        invoiceBrandsForParcelOverview: []
      }
      if (responseJSON && responseJSON.length) {
        nextState.invoiceBrandsForParcelOverview = responseJSON
      }
      this.setState(nextState)
    })
  }
}
